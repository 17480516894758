import request from '@/utils/request'

// 验证码1
export function captchaInit(parameter) {
  return request({
    url: 'api/captcha/init',
    method: 'get',
    data: parameter
  })
}

// 验证码2
export function captchaDraw(parameter) {
  return request({
    url: 'api/captcha/draw',
    method: 'post',
    data: parameter,
    responseType: 'blob'
  })
}

// 短信验证码
export function sendMessage(parameter) {
  return request({
    url: 'app/sign/sms',
    method: 'post',
    data: parameter
  })
}

// 注册
export function register(parameter) {
  return request({
    url: 'app/web/pcRegister',
    method: 'post',
    data: parameter
  })
}

// 登录
export function login(parameter) {
  return request({
    url: 'app/web/pcLogin',
    method: 'post',
    data: parameter
  })
}

// 短信登录
export function messageLogin(parameter) {
  return request({
    url: 'app/web/pcSMSLogin',
    method: 'post',
    data: parameter
  })
}

// 找回密码
export function retrievePassword(parameter) {
  return request({
    url: 'app/web/retrievePassword',
    method: 'post',
    data: parameter
  })
}

// 获取企业认证状态
export function authenticationType(parameter) {
  return request({
    url: 'app/member/company/info',
    method: 'post',
    data: parameter
  })
}

// 退出登录
export function logout(parameter) {
  return request({
    url: 'app/sign/out',
    method: 'GET',
    data: parameter
  })
}

// 获取邀请人信息
export function sysUserInfo(parameter) {
  return request({
    url: 'app/web/sysUserInfo',
    method: 'post',
    data: parameter
  })
}

// 获取行业级联
export function industryList(parameter) {
  return request({
    url: 'app/purchaseInfo/industry',
    method: 'post',
    data: parameter
  })
}

// 获取会员权益 
export function memberPackage(parameter) {
  return request({
    url: 'app/wep/memberPackage/hqEquity',
    method: 'post',
    data: parameter
  })
}